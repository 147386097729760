import {
  FC,
  HTMLAttributes,
  ReactNode,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  DocumentHead,
  AppBarWeb,
  AppBarMobile,
  DustLabsFooter,
  Footer,
  ClientOnly,
} from "@shared/components";
import { PageMeta, ProjectConfig } from "@shared/types";
import { GLOBAL_CONFIG, ThemeContext } from "@shared/constants";
import { AnimatePresence, motion } from "framer-motion";
import { useRouter } from "next/router";
import Image from "next/image";
import { useWindowSize } from "@dustlabs/web/hooks";
interface Props extends HTMLAttributes<HTMLDivElement> {
  pageMeta?: PageMeta;
  children: ReactNode;
  path: string;
  footer?: number;
  animation?: string;
  cancelAnimation?: boolean;
  forceCenter?: boolean;
}

const PageTemplate: FC<Props> = ({
  pageMeta,
  children,
  path,
  footer,
  animation,
  cancelAnimation,
  forceCenter = false,
  className,
  ...componentProps
}) => {
  const { darkMode } = useContext(ThemeContext);
  const { ASSETS, FAVICON, BASE_URL, ORG_NAME } =
    GLOBAL_CONFIG as ProjectConfig;
  const router = useRouter();
  const isExplorer = router.pathname.includes("explorer");
  const isY00tsLanding = ORG_NAME !== "DeGods" && router.pathname === "/";
  const explorerPagesStyle =
    "relative y00ts:lg:mt-10 w-full flex-1 mx-auto lg:py-20 y00ts:lg:pt-16 y00ts:pb-0 lg:px-24";
  const nonExplorerStyles =
    "relative lg:mt-32 w-full flex-1 mx-auto overflow-x-hidden max-w-[1400px]";
  const isDeGodsLandingPage = ORG_NAME === "DeGods" && router.pathname === "/";
  const y00tsLandingPageStyle =
    "relative w-screen flex-1 flex justify-center overflow-x-hidden lg:mt-20";
  const appBarAnimations = {
    initial: { opacity: 0, zIndex: 100 },
    animate: {
      opacity: 1,
      transition: {
        delay: cancelAnimation ? 1 : 4,
        duration: cancelAnimation ? 1 : 2,
      },
    },
    exit: { opacity: 0, transition: { duration: 1 } },
  };

  const [winWidth, winHeight] = useWindowSize();

  const [currentScrollTop, setCurrentScrollTop] = useState<number>(0);
  const renderNav = () =>
    winWidth >= 1024 ? (
      <AppBarWeb atTop={currentScrollTop === 0} />
    ) : (
      <AppBarMobile atTop={currentScrollTop === 0} />
    );

  return (
    <div
      {...componentProps}
      className={`flex flex-col w-full transition-colors ease-in-out  
      duration-700 bg-lightBackground dark:bg-darkBackground ${className} z-0`}
      style={{
        minHeight: winHeight,
      }}
    >
      <DocumentHead
        baseUrl={BASE_URL}
        pageMeta={pageMeta}
        assets={ASSETS}
        path={path}
        favicon={`${ASSETS}${FAVICON}`}
      />

      {/* without animation */}
      {!animation && renderNav()}

      {/* with animation */}
      <AnimatePresence exitBeforeEnter>
        {animation === "show" && (
          <motion.div {...appBarAnimations}>{renderNav()}</motion.div>
        )}
      </AnimatePresence>

      <main
        className={`${
          isExplorer
            ? explorerPagesStyle
            : isY00tsLanding
            ? y00tsLandingPageStyle
            : !isDeGodsLandingPage
            ? nonExplorerStyles
            : ""
        } ${
          forceCenter &&
          (isY00tsLanding
            ? "flex justify-center items-center h-fit md:h-screen pb-6"
            : !isDeGodsLandingPage
            ? "flex justify-center items-center xl:mt-14 heightSmall:mt-16 h-fit md:h-screen pb-6"
            : "flex flex-col justify-center items-center h-[calc(100vh-72px-72px)] lg:h-screen w-full")
        } scrollbar-none`}
        onScroll={(e) => setCurrentScrollTop(e.currentTarget.scrollTop)}
      >
        <AnimatePresence exitBeforeEnter>{children}</AnimatePresence>
      </main>

      <AnimatePresence exitBeforeEnter>
        {router.pathname === "/holdings" && darkMode && (
          <>
            <motion.div
              initial={{ left: -(winHeight * 0.0612) - 8 }}
              animate={{
                left: darkMode ? -8 : -(winHeight * 0.0612) - 8,
              }}
              exit={{
                left: -(winHeight * 0.0612) - 8,
              }}
              transition={{ delay: 0.5, ease: "easeOut" }}
              className="fixed top-0 -translate-x-[20%] md:translate-x-0 z-50"
              style={{ height: winHeight, aspectRatio: 0.0612 }}
            >
              <Image src="/y00ts/y2084/left-bar.svg" fill alt="|" />
            </motion.div>

            <motion.div
              initial={{ right: -(winHeight * 0.0612) - 8 }}
              animate={{
                right: darkMode ? -8 : -(winHeight * 0.0612) - 8,
              }}
              exit={{
                right: -(winHeight * 0.0612) - 8,
              }}
              transition={{ delay: 0.5, ease: "easeOut" }}
              className="fixed top-0 translate-x-[20%] md:translate-x-0 z-50"
              style={{ height: winHeight, aspectRatio: 0.0612 }}
            >
              <Image src="/y00ts/y2084/right-bar.svg" fill alt="|" />
            </motion.div>
          </>
        )}
      </AnimatePresence>

      {footer && <>{footer === 1 ? <Footer /> : <DustLabsFooter />} </>}
    </div>
  );
};

export default PageTemplate;
